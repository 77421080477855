<div>
  <form [formGroup]="formGroup" class="flex flex-column gap-4 mb-4">
    <div class="flex flex-column gap-2">
      <label for="name" class="font-medium">Nom</label>
      <input pInputText id="name" aria-describedby="name-help" formControlName="name" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="institution" class="font-medium">Institution</label>
      <input pInputText id="institution" aria-describedby="institution-help" value="institution.name" formControlName="institution" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="desc" class="font-medium">Description</label>
      <textarea id="desc" rows="5" cols="30" pInputTextarea formControlName="description"></textarea>
    </div>
  </form>
  <p-accordion class="mt-2" [multiple]="true" [activeIndex]="0">
    <p-accordionTab class="mb-2" header="Permissions">
      <group-permissions [selectedPermissions]="permissions" (permissionsChange)="permissionChange($event)"></group-permissions>
    </p-accordionTab>
    <p-accordionTab class="mb-2" header="Utilisateurs">
      <dynamic-table [data]="users" [headers]="userCols" [pageParams]="pageable" (paginate)="onPageChange($event)"></dynamic-table>
    </p-accordionTab>
  </p-accordion>
</div>
