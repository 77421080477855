import {Component, OnDestroy, OnInit} from '@angular/core';
import {InstitutionService} from "../../core/services/institution.service";
import {getDefaultPageable, Pageable, PageParams} from "../../core/models/page.model";
import {IInstitution} from "../../core/models/institution.model";
import {customDynamicDialog, DEFAULT_ROWS_PER_PAGE_OPTIONS} from "../../core/utils/utils";
import {ToastService} from "../../core/services/toast.service";
import {TableModule} from "primeng/table";
import {NgForOf} from "@angular/common";
import {ButtonDirective} from "primeng/button";
import {DynamicTableComponent} from "../../shared/components/dynamic-table/dynamic-table.component";
import {CustomDialogData} from "../../core/models/custom-dialog.model";
import {InstitutionDetailsComponent} from "./institution-details/institution-details.component";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {PaginatorState} from "primeng/paginator";
import {take} from "rxjs";
import {ToolbarComponent} from "../../shared/components/toolbar/toolbar.component";
import {CustomAction, CustomTableHeader} from "../../core/models/custom-table.model";

@Component({
  selector: 'app-institution',
  standalone: true,
  imports: [
    // Modules
    TableModule,
    // Components
    DynamicTableComponent, ToolbarComponent,
    // Directives & Pipes
    NgForOf, ButtonDirective
  ],
  templateUrl: './institution.component.html',
  styleUrl: './institution.component.scss'
})
export class InstitutionComponent implements OnInit, OnDestroy {

  institutions: IInstitution[] = [];
  columns: CustomTableHeader[] = [
    {
      key: 'name',
      column: 'Nom',
      type: 'text'
    },
    {
      key: 'phone',
      column: 'Téléphone',
      type: 'text'
    },
    {
      key: 'email',
      column: 'Email',
      type: 'text'
    },
    {
      key: 'type',
      column: 'Type',
      type: 'text'
    },
    {
      key: 'state',
      column: 'Etat',
      type: 'state'
    }
  ]
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: []};
  ref: DynamicDialogRef | undefined;
  actions: CustomAction[] = [];

  constructor(private institutionService: InstitutionService, private toastService: ToastService, public dialogService: DialogService) {}

  ngOnInit() {
    this.loadInstitutions();
    this.actions = this.getActions();
  }

  ngOnDestroy() {
    this.ref?.close();
  }

  getActions(): CustomAction[] {
    return [
      {
        role: 'READ_INSTITUTION',
        icon: 'pi pi-info-circle',
        label: 'View',
        severity: 'info',
        emit: (item) => this.viewOrEditInstitution(item)
      },
      {
        role: 'UPDATE_INSTITUTION',
        icon: 'pi pi-pen-to-square',
        label: 'Edit',
        severity: 'primary',
        emit: (item) => this.viewOrEditInstitution(item, true)
      },
      {
        role: 'DELETE_INSTITUTION',
        icon: 'pi pi-trash',
        label: 'Delete',
        severity: 'danger',
        emit: (item) => this.deleteInstitution(item)
      }
    ];
  }

  loadInstitutions(pageable: Pageable = getDefaultPageable()) {
    this.institutionService.getInstitutionByDeleted(false, pageable).subscribe({
      next: page => {
        this.institutions = page.content;
        this.pageable = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
        }
      },
      error: err => {
        this.toastService.showToast('Erreur lors du chargement des institutions', err, 'error');
      }
    })
  }

  createInstitution() {
    const data = {isCreate: true} as CustomDialogData;
    this.ref = this.dialogService.open(InstitutionDetailsComponent, customDynamicDialog('Création d\'institution', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadInstitutions());
  }

  viewOrEditInstitution(institution: IInstitution, isEdit: boolean = false) {
    const data = {isView: !isEdit, isEdit, data: institution} as CustomDialogData;
    this.ref = this.dialogService.open(InstitutionDetailsComponent, customDynamicDialog('Détails d\'institution', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadInstitutions());
  }

  deleteInstitution(institution: IInstitution) {
    this.institutionService.deleteInstitution(institution.id).subscribe({
      next: () => {
        this.toastService.showToast('Institution supprimée', `L'institution ${institution.name} a été supprimée avec succès.`);
        this.loadInstitutions();
      },
      error: err => {
        this.toastService.showToast('Erreur lors de la suppression de l\'institution', err, 'error');
      }
    });
  }

  toggleInstitution(institution: IInstitution) {
    this.institutionService.toggleInstitution(institution.id).subscribe({
      next: () => {
        this.toastService.showToast('Institution modifiée', `L'institution ${institution.name} a été modifiée avec succès.`);
        this.loadInstitutions();
      },
      error: err => {
        this.toastService.showToast('Erreur lors de la modification de l\'institution', err, 'error');
      }
    });
  }

  onPageChange = (event: PaginatorState) => this.loadInstitutions(getDefaultPageable(event.page, event.rows));

}
