import { Component, OnInit } from '@angular/core';
import { PasswordModule } from "primeng/password";
import { Button } from "primeng/button";
import { Ripple } from "primeng/ripple";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import {NgIf, NgOptimizedImage} from "@angular/common";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import { AuthService } from "../../core/services/auth.service";
import { ToastService } from "../../core/services/toast.service";
import { StorageService } from "../../core/services/storage.service";
import {InputOtpModule} from "primeng/inputotp";
import {AuthResponse} from "../../core/models/auth.model";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [PasswordModule, ReactiveFormsModule, InputTextModule, Button, Ripple, NgOptimizedImage, InputOtpModule, NgIf, RouterLink],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  login = new FormGroup({
    username: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    password: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
  });

  twoFactorForm = new FormGroup({
    code: new FormControl('', {nonNullable: true, validators: [Validators.required]})
  });

  showSpinner = false;
  isLoading: boolean = false;
  requiresTwoFactor: boolean = false;
  returnUrl: string = '';

  constructor(private route: ActivatedRoute, private authService: AuthService, private tokenManager: StorageService,
              public toastService: ToastService, private router: Router) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'] || '/main';
    });
    this.authService.getPublicKey().subscribe({
      next: (response) => {
        console.log('Public key: ', response.publicKey);
        this.tokenManager.setPublicKey(response.publicKey)
      },
      error: (err) => console.error('Error getting public key: ', err)
    });
  }

  onSubmit() {
    this.isLoading = true;
    this.showSpinner = true;

    if(!this.login.invalid && this.login.value.username && this.login.value.password) {
      this.authService.signIn(this.login.value.username, this.login.value.password).subscribe({
        next: response => {
          if (response.requiresTwoFactor) {
            this.requiresTwoFactor = true;
            this.toastService.showToast('Authentification à double facteur', 'Un code vous a été envoyé par email. Veuillez le saisir ci-dessous.');
          } else this.handleSuccessfulLogin(response);
          this.isLoading = false;
          this.showSpinner = false;
        },
        error: (err) => {
          this.toastService.showToast('Erreur lors de l\'authentification', err.error, 'error');
          this.isLoading = false;
          this.showSpinner = false;
        }
      });
    }
  }

  onSubmitTwoFactor() {
    this.isLoading = true;
    this.showSpinner = true;

    if (!this.twoFactorForm.invalid && this.twoFactorForm.value.code) {
      this.authService.verifyTwoFactor(this.login.value.username!, this.twoFactorForm.value.code).subscribe({
        next: response => {
          this.handleSuccessfulLogin(response);
          this.isLoading = false;
          this.showSpinner = false;
        },
        error: (err) => {
          this.toastService.showToast('Token invalide', err.error, 'error');
          this.isLoading = false;
          this.showSpinner = false;
        }
      });
    }
  }

  private handleSuccessfulLogin(response: AuthResponse) {
    this.toastService.showToast('Connexion réussie', 'Vous êtes maintenant connecté. Bienvenue !');
    this.tokenManager.setToken(response.access_token);
    this.tokenManager.setRefreshToken(response.refresh_token);
    this.router.navigate([this.returnUrl]).then();
  }
}
