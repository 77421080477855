import {Component, OnInit} from '@angular/core';
import {LogService} from "../../core/services/log.service";
import {TerminalModule} from "primeng/terminal";

@Component({
  selector: 'app-log',
  standalone: true,
  imports: [
    TerminalModule
  ],
  templateUrl: './log.component.html',
  styleUrl: './log.component.scss'
})
export class LogComponent implements OnInit {

  logs: string = '';
  constructor(private logService: LogService) { }

  ngOnInit() {
    this.logService.getOperationLogs().subscribe({
      next: value => {
        this.logs = value.join('\n');
        console.log('Logs:', this.logs);
      },
      error: (err) => console.log('Error:', err)
    });
  }
}
