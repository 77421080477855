import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {CustomDialogData} from "../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {IPermission} from "../../../core/models/permission.model";
import {IInstitution} from "../../../core/models/institution.model";
import {GroupService} from "../../../core/services/group.service";
import {ToastService} from "../../../core/services/toast.service";
import {AccordionModule} from "primeng/accordion";
import {UserService} from "../../../core/services/user.service";
import {getDefaultPageable, Pageable, PageParams} from "../../../core/models/page.model";
import {IUser} from "../../../core/models/user.model";
import {DynamicTableComponent} from "../../../shared/components/dynamic-table/dynamic-table.component";
import {PaginatorState} from "primeng/paginator";
import {DEFAULT_ROWS_PER_PAGE_OPTIONS} from "../../../core/utils/utils";
import {CheckboxModule} from "primeng/checkbox";
import {NgForOf} from "@angular/common";
import {Button} from "primeng/button";
import {GroupPermissionsComponent} from "../group-permissions/group-permissions.component";
import {CustomTableHeader} from "../../../core/models/custom-table.model";

@Component({
  selector: 'app-group-details',
  standalone: true,
  imports: [
    // Modules
    ReactiveFormsModule, InputTextModule, InputTextareaModule, AccordionModule, CheckboxModule,
    // Components
    DynamicTableComponent, GroupPermissionsComponent,
    // Directives & Pipes
    NgForOf, Button
  ],
  templateUrl: './group-details.component.html',
  styleUrl: './group-details.component.scss'
})
export class GroupDetailsComponent implements OnInit {

  formGroup = new FormGroup({
    name: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    institution: new FormControl<IInstitution>({} as IInstitution, {nonNullable: true, validators: [Validators.required]}),
    description: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    permissions: new FormControl<IPermission[]>([], {nonNullable: true, validators: [Validators.required]})
  });
  users: IUser[] = [];
  boundData: CustomDialogData | undefined;
  instance: DynamicDialogComponent | undefined;
  isCreate: boolean = false;
  userCols: CustomTableHeader[] = [
    {
      key: 'lastname',
      column: 'Nom',
      type: 'text'
    },
    {
      key: 'firstname',
      column: 'Prénom',
      type: 'text'
    },
    {
      key: 'enabled',
      column: 'Status',
      type: 'boolean'
    }
  ];
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: []};
  permissions: IPermission[] = [];


  constructor(private groupService: GroupService, private dialogService: DialogService, private userService: UserService,
              private toastService: ToastService, private ref: DynamicDialogRef) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.boundData = this.instance?.data;
    this.permissions = this.boundData?.data.permissions || [];
    this.loadUsersForGroup(getDefaultPageable());
    if (this.boundData?.isCreate) this.isCreate = true;
    if ((this.boundData?.isEdit || this.boundData?.isView) && this.boundData.data) this.formGroup.patchValue(this.boundData.data);
    if (this.boundData?.isView) this.formGroup.disable();
  }

  loadUsersForGroup(pageable: Pageable = getDefaultPageable(), ids: string[] = [this.boundData?.data.id]) {
    this.userService.getUsersByGroup(ids, pageable).subscribe({
      next: page => {
        this.users = page.content;
        this.pageable = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
        }
      },
      error: () => {
        this.toastService.showToast('Erreur lors du chargement des utilisateurs', 'Une erreur est survenue lors du chargement des utilisateurs.', 'error');
      }
    })

  }

  toggleGroup() {
    const enabled = this.boundData?.data.enabled;
    this.groupService.toggleGroup(this.boundData?.data.id).subscribe({
      next: () => {
        this.toastService.showToast(enabled ? 'Groupe désactivé' : 'Groupe activé', `Le groupe a été ${enabled ? 'désactivé' : 'activé'} avec succès.`);
        this.ref.close();
      },
      error: () => {
        this.toastService.showToast('Erreur lors de la modification du groupe', 'Une erreur est survenue lors de la modification du groupe. Veuillez réessayer plus tard.', 'error');
      }
    })
  }

  onPageChange = (event: PaginatorState) => this.loadUsersForGroup(getDefaultPageable(event.page, event.rows));

  permissionChange($event: IPermission[]) {
    console.log('Permissions: ', $event);
  }
}
