import {Component, OnInit} from '@angular/core';
import {ValidationService} from "../../core/services/validation.service";
import {StorageService} from "../../core/services/storage.service";
import {Direction, getDefaultPageable, Pageable, PageParams, Sort} from "../../core/models/page.model";
import {ToastService} from "../../core/services/toast.service";
import {IValidation} from "../../core/models/validation.model";
import {CustomAction, CustomTableHeader} from "../../core/models/custom-table.model";
import {PaginatorState} from "primeng/paginator";
import {customDynamicDialog, DEFAULT_ROWS_PER_PAGE_OPTIONS} from "../../core/utils/utils";
import {CustomDialogData} from "../../core/models/custom-dialog.model";
import {take} from "rxjs";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ViewValidationComponent} from "./view-validation/view-validation.component";
import {DynamicTableComponent} from "../../shared/components/dynamic-table/dynamic-table.component";
import {ToolbarComponent} from "../../shared/components/toolbar/toolbar.component";

@Component({
  selector: 'app-validation',
  standalone: true,
  imports: [
    DynamicTableComponent,
    ToolbarComponent
  ],
  templateUrl: './validation.component.html',
  styleUrl: './validation.component.scss'
})
export class ValidationComponent implements OnInit {

  validations: IValidation[] = [];
  sort: Sort = { direction: Direction.DESC, orders: [{ property: 'numberOfValidation', ignoreCase: false }] };
  columns: CustomTableHeader[] = [
    {
      key: 'product.description',
      column: 'Produit',
      type: 'text'
    },
    {
      key: 'numberOfValidation',
      column: 'Nombre de validation',
      type: 'text'
    },
    {
      key: 'isRequired',
      column: 'Obligatoire',
      type: 'boolean'
    },
    {
      key: 'orderAbsolute',
      column: 'Ordre absolu',
      type: 'boolean'
    }
  ]
  actions: CustomAction[] = [];
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: []};
  ref: DynamicDialogRef | undefined;

  constructor(private validationService: ValidationService, private tokenManager: StorageService,
              public dialogService: DialogService, private toastService: ToastService) { }

  ngOnInit() {
    this.setActions();
    this.loadValidations(getDefaultPageable(0, 10, this.sort));
  }

  loadValidations(pageable: Pageable) {
    const jwt = this.tokenManager.getToken();
    this.validationService.findValidationsByInstitutionId(jwt.institution_id, pageable).subscribe({
        next: (page) => {
          this.validations = page.content;
          this.pageable = {
            page: page.number,
            first: page.number * page.size,
            rows: page.size,
            totalRecords: page.totalElements,
            rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
          }
        },
        error: () => {
          const summary = 'Erreur de récupération';
          const detail = 'Une erreur est survenue lors de la récupération des validations';
          this.toastService.showToast(summary, detail, 'error');
        }
    });
  }

  setActions(): CustomAction[] {
    return [
      {
        role: 'READ_VALIDATION',
        icon: 'pi pi-info-circle',
        label: 'Consulter',
        severity: 'info',
        emit: (item) => this.viewValidation(item)
      },
      {
        role: 'DELETE_USER',
        icon: 'pi pi-trash',
        label: 'Supprimer',
        severity: 'danger',
        emit: (item) => this.deleteValidation(item)
      }
    ];
  }

  onPageChange = (event: PaginatorState) => this.loadValidations(getDefaultPageable(event.page, event.rows));

  private viewValidation(item: IValidation) {
    const data = {isView: true, data: item} as CustomDialogData;
    this.ref = this.dialogService.open(ViewValidationComponent, customDynamicDialog('Détails d\'une validation', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.ngOnInit());
  }

  private deleteValidation(item: IValidation) {
    this.validationService.deleteValidation(item.institutionId, item.productId).subscribe({
      next: () => {
        this.toastService.showToast('Suppression réussie', 'La validation a été supprimée avec succès', 'success');
        this.ngOnInit();
      },
      error: () => {
        this.toastService.showToast('Erreur de suppression', 'Une erreur est survenue lors de la suppression de la chaine de validation', 'error');
      }
    });
  }

}
