<p-table [value]="data" [tableStyle]="{ 'min-width': '50rem' }">
  <ng-template pTemplate="header">
    <tr>
      <th scope="col" *ngFor="let header of headers">{{ header.column }}</th>
      <th *ngIf="this.actions.length > 0" scope="col">Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-data>
    <tr>
      <td *ngFor="let header of headers">
        <ng-container [ngSwitch]="header.type">
          <span *ngSwitchCase="'text'">{{ getNestedValue(data, header.key) }}</span>
          <span *ngSwitchCase="'currency'">{{ getNestedValue(data, header.key) | currency : 'XOF' }}</span>
          <span *ngSwitchCase="'date'">{{ getNestedValue(data, header.key) | date : 'dd/MM/yyyy, hh:mm' }}</span>
          <span *ngSwitchCase="'state'">
            <span *ngIf="getNestedValue(data, header.key) == 'PENDING'">
              <p-tag [style]="{ background: 'var(--teal-200)' }">
                <span class="pi pi-stopwatch mr-1" [style]="{ color: 'var(--teal-800)' }"></span>
                <span [style]="{ color: 'var(--teal-800)' }">En attente</span>
              </p-tag>
            </span>
            <span *ngIf="getNestedValue(data, header.key) == 'ACTIVE'">
              <p-tag [style]="{ background: 'var(--green-200)' }">
                <span class="pi pi-check-circle mr-1" [style]="{ color: 'var(--green-500)' }"></span>
                <span [style]="{ color: 'var(--green-500)' }">Active</span>
              </p-tag>
            </span>
            <span *ngIf="getNestedValue(data, header.key) == 'INACTIVE'">
              <p-tag [style]="{ background: 'var(--red-200)' }">
                <span class="pi pi-times-circle mr-1" [style]="{ color: 'var(--red-500)' }"></span>
                <span [style]="{ color: 'var(--red-500)' }">Inactive</span>
              </p-tag>
            </span>
          </span>
          <span *ngSwitchCase="'boolean'">
            <p-tag *ngIf="getNestedValue(data, header.key) == true" [style]="{ background: 'var(--green-200)' }">
              <span class="pi pi-check-circle mr-1" [style]="{ color: 'var(--green-500)' }"></span>
              <span [style]="{ color: 'var(--green-500)' }">Actif</span>
            </p-tag>
            <p-tag *ngIf="getNestedValue(data, header.key) == false" [style]="{ background: 'var(--red-200)' }">
              <span class="pi pi-times-circle mr-1" [style]="{ color: 'var(--red-500)' }"></span>
              <span [style]="{ color: 'var(--red-500)' }">Inactif</span>
            </p-tag>
          </span>
          <span *ngSwitchDefault>{{ getNestedValue(data, header.key) }}</span>
        </ng-container>
      </td>
      <td *ngIf="this.actions.length > 0">
        <div class="card flex justify-content-center">
          <p-button [rounded]="true" size="small" [text]="true" [outlined]="true" severity="secondary"
                    (onClick)="emit(data, $event, menu);" icon="pi pi-ellipsis-v" />
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-menu #menu [model]="getActionsForRow()" [popup]="true" />
<div class="flex-auto">
  <p-paginator (onPageChange)="paginate.emit($event)"  [showCurrentPageReport]="true" currentPageReportTemplate="{last} / {totalRecords}"
               [first]="pageParams.first" [rows]="pageParams.rows" [totalRecords]="pageParams.totalRecords"
               [rowsPerPageOptions]="pageParams.rowPerPageOptions"></p-paginator>
</div>
