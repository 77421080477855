<div class="flex flex-column h-full">
  <form [formGroup]="formGroup" class="flex flex-column gap-4 mb-4">
    <div class="flex flex-column gap-2">
      <label for="firstname" class="font-medium">Prénom</label>
      <input pInputText id="firstname" aria-describedby="firstname-help" formControlName="firstname" />
      <small *ngIf="boundData?.isCreate" id="firstname-help">Renseigner ici le prénom de l'utilisateur.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="lastname" class="font-medium">Nom</label>
      <input pInputText id="lastname" aria-describedby="lastname-help" formControlName="lastname" />
      <small *ngIf="boundData?.isCreate" id="lastname-help">Renseigner ici le nom de l'utilisateur.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="username" class="font-medium">Nom d'utilisateur</label>
      <input pInputText id="username" aria-describedby="username-help" formControlName="username" />
      <small *ngIf="boundData?.isCreate" id="username-help">Renseigner ici le nom d'utilisateur.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="email" class="font-medium">Email</label>
      <input pInputText id="email" aria-describedby="email-help" formControlName="email" />
      <small *ngIf="boundData?.isCreate" id="email-help">Renseigner ici l'adresse mail de l'utilisateur.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="phoneNumber" class="font-medium">Téléphone</label>
      <input pInputText id="phoneNumber" aria-describedby="phone-help" formControlName="phoneNumber" />
      <small *ngIf="boundData?.isCreate" id="phone-help">Renseigner ici le numéro de téléphone de l'utilisateur.</small>
    </div>
    <div *ngIf="!boundData?.isView" class="flex flex-column gap-2">
      <label for="group" class="font-medium">Institution</label>
      <p-dropdown (onChange)="loadGroups($event)" [disabled]="!!boundData?.isView" id="institution" aria-describedby="institution-help" formControlName="institution"
                  [options]="institutions" optionLabel="name" placeholder="Sélectionner une institution"></p-dropdown>
      <small *ngIf="boundData?.isCreate" id="institution-help">Sélectionner l'institution de l'utilisateur.</small>
    </div>
    <div *ngIf="!boundData?.isView" class="flex flex-column gap-2">
      <label for="group" class="font-medium">Groupe</label>
      <p-dropdown [disabled]="!!boundData?.isView" id="group" aria-describedby="group-help" formControlName="group"
                  [options]="groups" optionLabel="name" placeholder="Sélectionner un groupe"></p-dropdown>
      <small *ngIf="boundData?.isCreate" id="group-help">Sélectionner le groupe de l'utilisateur.</small>
    </div>
  </form>
  <div class="flex flex-grow-1 gap-2 align-items-end justify-content-end">
    <p-button *ngIf="!boundData?.isCreate" pRipple [severity]="boundData?.data.enabled ? 'danger' : 'success'"
              [label]="boundData?.data.enabled ? 'Désactiver' : 'Activer'"
              (onClick)="toggleUser()"></p-button>
    <p-button pRipple *ngIf="!formGroup.disabled" [style]="{width: '100%'}"
              [label]=" boundData?.isCreate  ? 'Enregistrer' : 'Modifier'" [disabled]="!formGroup.valid"
              [loading]="isLoading" (onClick)="boundData?.isCreate ? create() : update()"></p-button>
  </div>
</div>
