import {Component, OnDestroy, OnInit} from '@angular/core';
import {IGroup} from "../../core/models/group.model";
import {DynamicTableComponent} from "../../shared/components/dynamic-table/dynamic-table.component";
import {getDefaultPageable, Pageable, PageParams} from "../../core/models/page.model";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {GroupService} from "../../core/services/group.service";
import {ToastService} from "../../core/services/toast.service";
import {customDynamicDialog, DEFAULT_ROWS_PER_PAGE_OPTIONS} from "../../core/utils/utils";
import {CustomDialogData} from "../../core/models/custom-dialog.model";
import {take} from "rxjs";
import {PaginatorState} from "primeng/paginator";
import {GroupDetailsComponent} from "./group-details/group-details.component";
import {ToolbarComponent} from "../../shared/components/toolbar/toolbar.component";
import {CustomAction, CustomTableHeader} from "../../core/models/custom-table.model";

@Component({
  selector: 'app-group',
  standalone: true,
  imports: [
    DynamicTableComponent,
    ToolbarComponent
  ],
  templateUrl: './group.component.html',
  styleUrl: './group.component.scss'
})
export class GroupComponent implements OnInit, OnDestroy {

  groups: IGroup[] = [];
  columns: CustomTableHeader[] = [
    {
      key: 'name',
      column: 'Nom',
      type: 'text'
    },
    {
      key: 'institution.name',
      column: 'Institution',
      type: 'text'
    },
    {
      key: 'enabled',
      column: 'Status',
      type: 'boolean'
    }
  ]
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: []};
  ref: DynamicDialogRef | undefined;
  actions: CustomAction[] = [];

  constructor(private groupService: GroupService, private toastService: ToastService, public dialogService: DialogService) {}

  ngOnInit() {
    this.loadGroups();
    this.actions = this.getActions();
  }

  ngOnDestroy() {
    this.ref?.close();
  }

  getActions(): CustomAction[] {
    return [
      {
        role: 'READ_GROUP',
        icon: 'pi pi-info-circle',
        label: 'View',
        severity: 'info',
        emit: (item) => this.viewOrEditGroup(item)
      },
      {
        role: 'UPDATE_GROUP',
        icon: 'pi pi-pen-to-square',
        label: 'Edit',
        severity: 'primary',
        emit: (item) => this.viewOrEditGroup(item, true)
      },
      {
        role: 'DELETE_GROUP',
        icon: 'pi pi-trash',
        label: 'Delete',
        severity: 'danger',
        emit: (item) => this.deleteGroup(item)
      }
    ];
  }

  loadGroups(pageable: Pageable = getDefaultPageable()) {
    this.groupService.getAllGroups(pageable).subscribe({
      next: page => {
        this.groups = page.content;
        this.pageable = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
        }
      },
      error: err => {
        this.toastService.showToast('Erreur lors du chargement des groups', err, 'error');
      }
    })
  }

  viewOrEditGroup(group: IGroup, isEdit: boolean = false) {
    const data = {isView: !isEdit, isEdit, data: group} as CustomDialogData;
    this.ref = this.dialogService.open(GroupDetailsComponent, customDynamicDialog('Détails du groupe', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadGroups());
  }

  deleteGroup(group: IGroup) {
    this.groupService.deleteGroup(group.id).subscribe({
      next: () => {
        this.toastService.showToast('Group supprimée', `Le groupe ${group.name} a été supprimé avec succès.`);
        this.loadGroups();
      },
      error: err => {
        this.toastService.showToast('Erreur lors de la suppression du groupe', err, 'error');
      }
    });
  }

  onPageChange = (event: PaginatorState) => this.loadGroups(getDefaultPageable(event.page, event.rows));

}
