import {Component} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {CustomDialogData} from "../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {GroupService} from "../../../core/services/group.service";
import {UserService} from "../../../core/services/user.service";
import {ToastService} from "../../../core/services/toast.service";
import {InputNumberModule} from "primeng/inputnumber";

@Component({
  selector: 'app-devise-details',
  standalone: true,
  imports: [ReactiveFormsModule, InputTextModule, InputNumberModule],
  templateUrl: './devise-details.component.html',
  styleUrl: './devise-details.component.scss'
})
export class DeviseDetailsComponent {

  formGroup = new FormGroup({
    description: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    code: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    symbole: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
    rate: new FormControl<number>(1, {nonNullable: true, validators: [Validators.required]})
  });
  boundData: CustomDialogData | undefined;
  instance: DynamicDialogComponent | undefined;
  isCreate: boolean = false;


  constructor(private groupService: GroupService, private dialogService: DialogService, private userService: UserService,
              private toastService: ToastService, private ref: DynamicDialogRef) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.boundData = this.instance?.data;
    if (this.boundData?.isCreate) this.isCreate = true;
    if ((this.boundData?.isEdit || this.boundData?.isView) && this.boundData.data) this.formGroup.patchValue(this.boundData.data);
    if (this.boundData?.isView) this.formGroup.disable();
  }


}
