<div class="card flex justify-content-start mb-2">
  <div class="flex flex-column gap-2">
    <div *ngFor="let permission of permissions" class="field-checkbox">
      <p-checkbox [(ngModel)]="selectedPermissions" [label]="permission.description" name="group" [value]="permission" />
    </div>
  </div>
</div>
<div class="flex justify-content-end">
  <p-button severity="secondary" (onClick)="permissionsChange.emit(selectedPermissions)" [label]="'Modifier'" ></p-button>
</div>
