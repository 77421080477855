<div>
  <form [formGroup]="formGroup" class="flex flex-column gap-4 mb-4">
    <div class="flex flex-column gap-2">
      <label for="name" class="font-medium">Devise</label>
      <input pInputText id="name" aria-describedby="name-help" formControlName="description" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="code" class="font-medium">Code</label>
      <input pInputText id="code" aria-describedby="code-help" formControlName="code" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="symbole" class="font-medium">Symbole</label>
      <input pInputText id="symbole" aria-describedby="symbole-help" formControlName="symbole" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="code" class="font-medium">Taux</label>
      <p-inputNumber formControlName="rate" mode="decimal" [showButtons]="true"/>
    </div>
  </form>
</div>
