import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  private apiUrl = '/v1/logs';  // Base URL for the logs API

  constructor(private http: HttpClient) { }

  /**
   * Get logs from the backend API for operation service.
   *
   * @param limit The number of log lines to retrieve. Defaults to 1000.
   * @returns An Observable of the log lines as an array of strings.
   */
  getOperationLogs(limit: number = 1000): Observable<string[]> {
    let params = new HttpParams().set('limit', limit.toString());
    return this.http.get<string[]>(`operation${this.apiUrl}`, { params });
  }

  /**
   * Get logs from the backend API for operation service.
   *
   * @param limit The number of log lines to retrieve. Defaults to 1000.
   * @returns An Observable of the log lines as an array of strings.
   */
  getCoreLogs(limit: number = 1000): Observable<string[]> {
    let params = new HttpParams().set('limit', limit.toString());
    return this.http.get<string[]>(`core${this.apiUrl}`, { params });
  }

  /**
   * Get logs from the backend API for operation service.
   *
   * @param limit The number of log lines to retrieve. Defaults to 1000.
   * @returns An Observable of the log lines as an array of strings.
   */
  getAuthenticationLogs(limit: number = 1000): Observable<string[]> {
    let params = new HttpParams().set('limit', limit.toString());
    return this.http.get<string[]>(`auth${this.apiUrl}`, { params });
  }
}
