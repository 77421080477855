<p-toolbar>
  <div class="p-toolbar-group-start">
    <div class="font-bold text-2xl text-color-secondary"> {{ title }} </div>
  </div>
  <div class="p-toolbar-group-center">
        <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText placeholder="Recherche" />
        </span>
  </div>
  <div class="p-toolbar-group-end">
    <p-button *ngIf="create.observed" icon="pi pi-plus" (onClick)="this.create.emit()" class="mr-2"/>
    <p-button icon="pi pi-upload" (onClick)="this.export.emit()" />
  </div>
</p-toolbar>
