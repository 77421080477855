import {Component, OnInit} from '@angular/core';
import {CheckboxModule} from "primeng/checkbox";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {NgIf} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Button} from "primeng/button";
import {Ripple} from "primeng/ripple";
import {CustomDialogData} from "../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {DeviseService} from "../../../core/services/devise.service";
import {ToastService} from "../../../core/services/toast.service";
import {IDevise} from "../../../core/models/devise.model";
import {IAccount} from "../../../core/models/account.model";
import {AccountService} from "../../../core/services/account.service";
import {getUnpagedPageable} from "../../../core/models/page.model";
import {InstitutionService} from "../../../core/services/institution.service";
import {getInstitutionTypeByValue, IInstitution, InstitutionType} from "../../../core/models/institution.model";

@Component({
  selector: 'app-account-details',
  standalone: true,
  imports: [
    // Modules
    CheckboxModule, DropdownModule, InputTextModule, ReactiveFormsModule, Button, Ripple,
    // Pipes & Directives
    NgIf
  ],
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {

  formGroup = new FormGroup({
    number: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    libelle: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    devise: new FormControl<IDevise | null>(null, {nonNullable: true, validators: [Validators.required]}),
    rib: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    swiftId: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    ribKey: new FormControl(''),
    iban: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    codeAgency: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    bankName: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    institutionId: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
  });

  boundData: CustomDialogData | undefined;
  isLoading: boolean = false;
  instance: DynamicDialogComponent | undefined;
  devises: IDevise[] = [];
  institutions: IInstitution[] = [];
  account: IAccount = {} as IAccount;

  constructor(private accountService: AccountService, private dialogService: DialogService, private deviseService: DeviseService,
              private toastService: ToastService, private ref: DynamicDialogRef, private institutionService: InstitutionService) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.getDevises();
    this.getInstitutions();
    this.boundData = this.instance?.data;
    if (!this.boundData?.isCreate) {
      this.account = this.boundData?.data as IAccount;
      this.formGroup.patchValue(this.boundData?.data);
      this.formGroup.patchValue({devise: this.account.devise});
      if (this.boundData?.isView) this.formGroup.disable();
    }
  }

  getInstitutions() {
    this.institutionService.getInstitutionByType([getInstitutionTypeByValue(InstitutionType.BANK)!], getUnpagedPageable()).subscribe({
      next: (page) => this.institutions = page.content,
      error: (err) => {
        const summary = 'Récupération des institutions';
        this.toastService.showToast(summary, err.error, 'error');
      }
    });
  }

  getDevises() {
    this.deviseService.getAllDevises(getUnpagedPageable()).subscribe({
      next: (page) => {
        this.devises = page.content;
        this.formGroup.patchValue({ devise: this.devises[0] });
      },
      error: () => {
        const summary = 'Récupération des devises';
        const detail = 'Une erreur est survenue lors de la récupération des devises. Veuillez réessayer plus tard.';
        this.toastService.showToast(summary, detail, 'error');
      }
    });
  }

  create() {
    this.isLoading = true;
    const accountDto = this.formGroup.value as IAccount;
    this.accountService.createAccount(accountDto).subscribe({
      next: (account) => {
        this.toastService.showToast('Compte créé', `Le compte ${account.libelle} a été créé avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la création du compte', error, 'error');
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

  update() {
    this.isLoading = true;
    const accountDto = this.formGroup.value as IAccount;
    this.accountService.updateAccount(this.boundData?.data.id, accountDto).subscribe({
      next: (account) => {
        this.toastService.showToast('Compte modifié', `Le compte ${account.libelle} a été modifié avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la modification du compte', error);
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

}
