import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {TokenManagerService} from "../services/token-manager.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private tokenManagerService: TokenManagerService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.tokenManagerService.isTokenExpired()) return true;
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } }).then();
    return false;
  }

}
